.card {
	color: black;
}

.technologies {
	color: #6c757d;
	letter-spacing: 4px;
}

.card-body {
	height: fit-content;
	min-height: 22vh;
}

.icons svg {
	margin-left: 0.25em;
	margin-right: 0.25em;
}

.small-header {
	font-size: 1.8em!important;
}

#root {
	overflow-y:auto!important;
}
