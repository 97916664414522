#home {
	margin-top: 10%;
	padding-bottom: 2.5em;
}

#wave {
	z-index: -1;
	position: absolute;
	width: 275px;
}

#portrait {
	margin-left: 35px;
	margin-top: 15px;
	border-radius: 50%;
	width: 200px;
	position: absolute;
}

.portrait-container {
	position: relative;
	height: 232px;
}

@media screen and (max-width: 768px) and (min-width: 520px){
	.portrait-container {
		margin-left: 25%;
	}
}
@media screen and (max-width: 519px) and (min-width: 433px){
	.portrait-container {
		margin-left: 20%;
	}
}
@media screen and (max-width: 433px){
	.portrait-container {
		margin-left: 10%;
	}
}
#hand-wave {
	animation-name: wave-animation;
	animation-duration: 2.5s;
	animation-iteration-count: 2;
	transform-origin: 70% 70%;
	display: inline-block;
} 

@keyframes wave-animation {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(14deg);
	}
	20% {
		transform: rotate(-8deg);
	}
	30% {
		transform: rotate(14deg);
	}
	40% {
		transform: rotate(-4deg);
	}
	50% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

.larger-text {
  font-size: 1.5em;
}

.link {
	cursor: pointer;
}

.link:hover {
	color: #D05700;	
}