@media screen and (max-width: 576px) {
	#main {
		margin-left: 0.25em;
	}
}

#main {
	position: relative;
	min-height: 98vh;
	max-height: 100vh;
}
body {
  overflow-x: hidden
}
#root {
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 100vh;
}