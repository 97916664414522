.buttons {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	border: 1px solid #000;
	position: relative;
	top: 4px;
	left: 6px;
	background-color: #ff3b47;
	border-color: #9d252b;
	display: inline-block;
}

.minimize {
	left: 11px;
	background-color: #ffc100;
	border-color: #9d802c;
}

.maximize {
	left: 16px;
	background-color: #00d742;
	border-color: #049931;
}

.bar {
	width: 85%;
	box-sizing: border-box;
	height: 25px;
	background-color: #bbb;
	margin: 0 auto;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.window {
	background-color: #151515;
	box-sizing: border-box;
	width: 85%;
	margin: 0 auto;
	padding: 20px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.window p {
	text-align: left;
	font-family: monospace;
	white-space: normal;
	overflow: hidden;
}

.line1 {
	color: #9cd9f0;
}

.line2 {
	color: #cdee69;
}

.line3 {
	color: #e09690;
}

.line4 {
	color: #fff;
}
