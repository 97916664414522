#about {
  padding-bottom: 2.5rem;
}

#ship1 {
  margin-top: 40%;
}

#planet2 {
  margin-left: -45%;
}

#satelite {
  margin-top: -25%;
}

#planet4 {
  margin-bottom: -50%;
}

.stars {
  position: absolute;
}

.terminal {
  margin-top: 25%;
}